import React from "react"
import tw from "twin.macro"
import { graphql, Link, useStaticQuery } from "gatsby"

import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "../components/container"
import Hero from "../components/hero"
import Layout from "../components/layout"

const Sitemap = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "sitemap/hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Hero styles={tw`mb-12 pb-0 lg:(pt-28 mb-0) xl:(pt-32 pb-24)`}>
        <Container>
          <div tw="grid grid-cols-4 gap-x-7 lg:(grid-cols-12)">
            <div tw="col-span-full mb-20 lg:(col-span-8 mb-0)">
              <h1 tw="uppercase font-extrabold text-[32px] [line-height: 36px] mb-6 lg:(text-43 mb-8 w-full) xl:(text-6xl mb-12 [line-height: 1.4])">
                Sitemap
              </h1>
              <div tw="font-bold text-base xl:(text-[22px] [line-height: 32px])">
                <div tw="w-full lg:(flex justify-between items-start mb-12) xl:mb-28">
                  <Link to="/" tw="block mb-8 lg:(inline mb-0)">
                    Home
                  </Link>
                  <Link to="/what-is-cmv/" tw="block mb-8 lg:(inline mb-0)">
                    Understanding CMV after transplant
                  </Link>
                  <Link
                    to="/what-is-livtencity/"
                    tw="block mb-8 lg:(inline mb-0)"
                  >
                    What is LIVTENCITY?
                  </Link>
                </div>
                <div tw="font-bold w-full lg:(flex justify-between items-start)">
                  <Link to="/dosing/" tw="block mb-8 lg:(inline mb-0)">
                    What should I know about
                    <br tw="hidden lg:block" /> taking
                    <br tw="lg:hidden" /> LIVTENCITY?
                  </Link>
                  <Link to="/resources/" tw="block mb-8 lg:(inline mb-0)">
                    Support & resources
                    <span tw="font-light block italic">Apoyo y recursos</span>
                  </Link>
                  <Link to="/patient-support/" tw="block lg:inline">
                    Takeda Patient Support
                  </Link>
                </div>
              </div>
            </div>
            <div tw="col-span-full lg:(col-span-4)">
              <GatsbyImage
                image={getImage(data.heroImage)}
                alt=""
                tw="relative -scale-x-100 w-[100%] mt-[-220px] mb-[-100px] right-[-80px] max-w-[300px] float-right lg:(float-none right-[unset] max-w-[unset] w-[110%] mt-[-30px] ml-[-30px] mb-[unset])"
              />
            </div>
          </div>
        </Container>
      </Hero>
    </Layout>
  )
}

export const Head = () => <Seo title="Sitemap | LIVTENCITY® (maribavir)" />

export default Sitemap
